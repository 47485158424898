import { useState } from 'react'

const useInvertedColorsButton: Common.InvertedColorsButton.Service = props => {
    const [backgroundColor, backgroundColor_set] = useState<string>(props.backgroundColor)
    const [textColor, textColor_set] = useState<string>(props.textColor)
    const [boxShadow, boxShadow_set] = useState<string | undefined>(undefined)
    const [gradient, gradient_set] = useState<string | undefined>(props.gradient)
    const mouseEnterHandler = (e: any) => {
        backgroundColor_set(props.textColor)
        textColor_set(props.backgroundColor)
        boxShadow_set('0px 0px 2px 2px ' + props.backgroundColor)
        gradient_set(undefined)
    }
    const mouseLeaveHandler = (e: any) => {
        backgroundColor_set(props.backgroundColor)
        textColor_set(props.textColor)
        boxShadow_set(undefined)
        gradient_set(props.gradient)
    }
    return {
        colors: {
            background: backgroundColor,
            text: textColor,
        },
        gradient,
        boxShadow,
        mouseEnterHandler,
        mouseLeaveHandler,
    }
}

export default useInvertedColorsButton
