import React from "react";
import IMPRECO_1x from "./Resources/IMPRECO-2-1x.png";
import IMPRECO_2x from "./Resources/IMPRECO-2-2x.png";
import { RouteComponentProps, Link } from "react-router-dom";
import POconstants from "../../../Utils/POconstants.js";
import { useLocale } from "../../../extensions/Locale/useLocale";
import NewResponsiveImage from "../NewResponsiveImage/ResponsiveImage";

const ImprecoLogo = () => {
     const { currentLang } = useLocale<Locale.Keys>();
     return (
          <a
               href={POconstants.externalLinks.impreco + (currentLang === "hr" ? "" : "?lang=" + currentLang)}
               style={{ maxWidth: "80vw" }}
               target='_blank'
               rel='noopener noreferrer'
          >
               <NewResponsiveImage
                    src={IMPRECO_1x}
                    responsiveData={{
                         srcsetData: [
                              { src: IMPRECO_1x, width: "300w" },
                              { src: IMPRECO_2x, width: "600w" },
                         ],
                         sizes: "300px",
                    }}
                    style={{ maxWidth: "100%" }}
                    alt='IMPRECO logo'
               />
          </a>
     );
};

export default ImprecoLogo;
