import React, { useState, useEffect, Suspense } from "react";
import Helmet from "react-helmet";
import { useLocale } from "./extensions/Locale/useLocale";
import POconstants from "./Utils/POconstants";
import ImprecoLogo from "./Components/Common/ImprecoLogo/ImprecoLogo";
import { getJsonFromUrl } from "./Utils/ClientUtil";
import ReactGA from "react-ga";
import NavBar2 from "./Components/NewNavBar2/NavBar";
import "./ImprecoApp.scss";
import hr from "./extensions/Locale/hr";
import en from "./extensions/Locale/en";
import { useHistory, BrowserRouter, Route } from "react-router-dom";
import { StateProvider } from "./extensions/StateManager";
import ToolBar from "./Components/ToolBar/ToolBar";
import PageIsLoading from "./Components/Common/PageIsLoading/PageIsLoading";
import InvertedColorsButton from "./Components/Common/InvertedColorsButton/InvertedColorsButton";

const ImprecoApp = () => {
  return (
    <StateProvider>
      <BrowserRouter>
        <AppBody />
      </BrowserRouter>
    </StateProvider>
  );
};

const AppBody = () => {
  const { lang, currentLang } = useLocale<Locale.Keys>();
  const { setLanguages } = useLocale<Locale.Keys>();
  const [localeLoaded, localeLoaded_set] = useState<boolean>(false);
  const URLLang = getJsonFromUrl(window.location.href).lang;
  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === "production" ||
      process.env.REACT_APP_ENVIRONMENT === "test"
    ) {
      const trackingId = process.env.REACT_APP_GA_ID;
      if (trackingId) {
        ReactGA.initialize(trackingId);
      } else console.error("Unable to load Google analytics id env variable");
    }
    let currentLang: "en" | "hr" = URLLang === "en" ? "en" : "hr";
    let lang: Locale.State<Locale.Keys> = {
      langKey: currentLang,
      hr: hr(),
      en: en(),
    };
    setLanguages(lang);
    localeLoaded_set(true);
  }, [URLLang]);
  const history = useHistory();
  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT === "production" ||
      process.env.REACT_APP_ENVIRONMENT === "test"
    ) {
      ReactGA.set({ page: history.location.pathname });
      ReactGA.pageview(history.location.pathname);
    }
  }, [history.location.pathname]);

  return localeLoaded ? (
    <>
      <Helmet>
        <meta name="description" content={lang.pageDescription} />
        <meta charSet="utf-8" />
        <title>{lang.titles.impreco}</title>
        <link
          rel="alternate"
          hrefLang="hr"
          href={POconstants.externalLinks.impreco}
        ></link>
        <link
          rel="alternate"
          hrefLang="en"
          href={POconstants.externalLinks.impreco + "?lang=en"}
        ></link>
        <link
          rel="alternate"
          hrefLang="x-default"
          href={POconstants.externalLinks.impreco + "?lang=en"}
        ></link>
        <link
          rel="canonical"
          href={
            POconstants.externalLinks.impreco + currentLang === "en"
              ? "?lang=en"
              : ""
          }
        />
      </Helmet>
      <ToolBar />
      <Suspense fallback={<PageIsLoading />}>
        <Route path="*" component={NavBar2} />
        <div className="pageContentImprecoSpecial">
          <h2>{lang.imprecoTitle}</h2>
          <div className="imprecoLogoRow">
            <ImprecoLogo />
            <div className="imprecoDataTable">
              <div>{lang.impreco1_1}</div>
              <div>{lang.impreco1_2}</div>
              <div>{lang.impreco2_1}</div>
              <div>{lang.impreco2_2}</div>
              <div>{lang.impreco3_1}</div>
              <div>{lang.impreco3_2}</div>
              <div>{lang.impreco4_1}</div>
              <div>{lang.impreco4_2}</div>
              <div>{lang.impreco5_1}</div>
              <div>{lang.impreco5_2}</div>
              <div>{lang.impreco6_1}</div>
              <div>{lang.impreco6_2}</div>
            </div>
          </div>
          <div className="imprecoButtonRow">
            <InvertedColorsButton
              backgroundColor={POconstants.colors.primary}
              textColor="white"
              text="IMPRECO E-LEARNING"
              link={{
                type: "external",
                url: POconstants.externalLinks.quiz!,
              }}
            />
            <InvertedColorsButton
              backgroundColor={POconstants.colors.primary}
              textColor="white"
              text="IMPRECO COMMON EDUCATIONAL PROGRAMME"
              link={{
                type: "internal",
                url: POconstants.siteLinks.educationalProgramme!,
              }}
            />
          </div>
          <p>{lang.pages.impreco.p1}</p>
          <ul>
            <li>{lang.pages.impreco.b1}</li>
            <li>{lang.pages.impreco.b2}</li>
            <li>{lang.pages.impreco.b3}</li>
            <li>{lang.pages.impreco.b4}</li>
          </ul>
          <div className="imprecoButtonRow">
            <InvertedColorsButton
              backgroundColor={POconstants.colors.primary}
              textColor="white"
              text="IMPRECO GIS"
              link={{
                type: "external",
                url: POconstants.externalLinks.geoServer!,
              }}
            />
            <InvertedColorsButton
              backgroundColor={POconstants.colors.primary}
              textColor="white"
              text="IMPRECO DB"
              link={{
                type: "external",
                url: POconstants.externalLinks.impreco!,
              }}
            />
            <InvertedColorsButton
              backgroundColor={POconstants.colors.primary}
              textColor="white"
              text="IMPRECO OPEN DATABASE"
              link={{
                type: "external",
                url: POconstants.externalLinks.adriawet!,
              }}
            />
            <InvertedColorsButton
              backgroundColor={POconstants.colors.primary}
              textColor="white"
              text={
                "IMPRECO" +
                (currentLang === "hr" ? " REPOZITORIJ" : " REPOSITORY")
              }
              link={{
                type: "external",
                url: POconstants.externalLinks.imprecoRepository!,
              }}
            />
          </div>
          <p style={{ marginBottom: "0" }}>{lang.impreco10}</p>
          {/* <p>{lang.impreco7}</p> */}
          {/* <p>{lang.impreco9}</p> */}
          <p style={{ marginTop: "0" }}>
            IMPRECO:{" "}
            <a
              href="https://impreco.adrioninterreg.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://impreco.adrioninterreg.eu/
            </a>
          </p>
        </div>
      </Suspense>
      <div style={{ margin: "0 3rem 1rem 3rem", borderTop: "1px dotted" }}>
        {currentLang === "hr"
          ? `Ova web stranica je izrađena uz financijsku pomoć Europske unije. Sadržaj web stranice je isključiva odgovornost Javne ustanove za upravljanje zaštićenim dijelovima prirode na području Splitsko-dalmatinske županije "More i Krš" i ni pod kojim okolnostima se ne može smatrati da odražava stavove nadležnih tjela Europske unije i/ili programa ADRION.`
          : `This website was created with the financial assistance of the European Union. The content of the website is the sole responsibility of Public Institution for the Management of Protected Areas in the County of Split and Dalmatia "Sea and Karst” and can under no circumstances be regarded as reflecting the position of the European Union and/or ADRION program authorities.`}
      </div>
    </>
  ) : null;
};

export default ImprecoApp;
