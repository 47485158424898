import { useContext } from 'react'
import { StateManager } from '../StateManager'
import { updateLocalStorage } from '../../Utils/ClientUtil'

export function useLocale<K>(): Locale.Store<K> {
    const storeName: string = 'lang'
    const { updateStore, store } = useContext(StateManager)
    let langState: Locale.State<K> = store.lang
    let lang: any =
        langState && langState[langState.langKey as keyof Locale.State<K>] ? langState[langState.langKey as keyof Locale.State<K>] : {}

    const updateLanguage = (language: Locale.Languages) => {
        console.log('Update lang', language)
        updateLocalStorage<string>('lang', language)
        updateStore<Locale.State<K>, Locale.Action<K>>(storeName, localeReducer, {
            type: 'CHANGE_LANGUAGE',
            langKey: language,
        })
    }
    const setLanguages = (state: Locale.State<K>): void => {
        console.log('Set lang', state)
        updateStore<Locale.State<K>, Locale.Action<K>>(storeName, localeReducer, {
            type: 'SET_LANGUAGES',
            ...state,
        })
    }
    return {
        lang: lang,
        currentLang: langState ? langState.langKey : undefined,
        updateLanguage: updateLanguage,
        setLanguages: setLanguages,
    }
}
function localeReducer<K>(state: Locale.State<K>, action: Locale.Action<K>): Locale.State<K> {
    let { type, ...newState } = action
    switch (action.type) {
        case 'CHANGE_LANGUAGE':
            return {
                ...state,
                langKey: newState.langKey,
            }
        case 'SET_LANGUAGES':
            return {
                ...state,
                ...newState,
            }
    }
}
