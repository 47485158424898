import React, { useContext } from "react";
import { StateManager } from "../../extensions/StateManager";
import { switchLanguages } from "../../Utils/ClientUtil";
import LanguageIcon from "./languageIcon.svg";

export default function LanguageButton() {
     const clickHandler = (langKey: keyof Locale.LanguagesInterface) => {
          const newUrl = switchLanguages(langKey);
          if (newUrl) window.location.href = newUrl;
     };
     const languageList: Locale.LanguageList<any> = {
          hr: (
               <div
                    key='laB1'
                    className='languageButton'
                    onClick={() => {
                         clickHandler("hr");
                    }}
               >
                    {/* <img src={CroFlag} className='languageFlag' /> */}
                    <img className='languageIcon' src={LanguageIcon} />
                    HRVATSKI
               </div>
          ),
          en: (
               <div
                    key='laB2'
                    className='languageButton'
                    onClick={() => {
                         clickHandler("en");
                    }}
               >
                    {/* <img src={EngFlag} className="languageFlag" /> */}
                    <img className='languageIcon' src={LanguageIcon} />
                    ENGLISH
               </div>
          ),
     };
     const { store } = useContext(StateManager);
     let currentLanguage: keyof Locale.LanguagesInterface = store.lang ? store.lang.langKey : null;
     return currentLanguage ? languageList[currentLanguage === "en" ? "hr" : "en"] : null;
}
