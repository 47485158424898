import React, { useEffect, useState } from 'react'
import DropDown from './Components/DropDown/DropDown'
import { useLocale } from '../../extensions/Locale/useLocale'
import POconstants from '../../Utils/POconstants.js'
import { NavBarNS2 } from './_NavBar'
import HamburgerIcon from './Resources/hamburgerIcon.svg'
import LogoPic from './Resources/bezTeksta.png'
import { Link, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

const NavBar: NavBarNS2.Navbar = (props) => {
    const { lang, currentLang } = useLocale<Locale.Keys>()
    const [isOnTop, isOnTop_set] = useState<boolean>(true)
    const scrollHandler = (e: any) => {
        const offsetTop = window.scrollY
        isOnTop_set(offsetTop === 0)
    }
    const [hamburgerOn, hamburgerOn_set] = useState<boolean>(false)
    const toggleHamburger = () => hamburgerOn_set((current) => !current)
    const isOnImprecoSite = window.location.hostname.includes('impreco')
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
    }, [])
    return (
        <div className={'navBar' + (isOnTop ? '' : ' sticky')}>
            {isOnImprecoSite ? (
                <a
                    href={
                        process.env.REACT_APP_BASE_URL + POconstants.siteLinks.home + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                    }
                    onClick={() => hamburgerOn_set(false)}
                >
                    <img className="navLogo" src={LogoPic} alt={lang.alt.nav.POLogo}></img>
                </a>
            ) : (
                <Link
                    to={POconstants.siteLinks.home + (currentLang === 'hr' ? '' : '?lang=' + currentLang)}
                    onClick={() => hamburgerOn_set(false)}
                >
                    <img className="navLogo" src={LogoPic} alt={lang.alt.nav.POLogo}></img>
                </Link>
            )}

            <DropDown
                parentSaysShouldShow={true}
                toggleShowParent={() => {}}
                isChild={false}
                hash={lang.Beaches}
                class={hamburgerOn ? ' expanded' : undefined}
                options={[
                    {
                        title: lang.PakleniIslands.toLocaleUpperCase(),
                        key: 'b1',
                        isInNavBar: true,
                        subMenus: [
                            {
                                title: lang.GeneralInfo.toLocaleUpperCase(),
                                key: 'b1-1',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.generalInfo + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.generalInfo +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                            {
                                title: lang.NatHeritage.toLocaleUpperCase(),
                                key: 'b1-2',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.naturalHeritage + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.naturalHeritage +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                            {
                                title: lang.GeoPaleoHeritage.toLocaleUpperCase(),
                                key: 'b1-4',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.geoPaleoHeritage + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.geoPaleoHeritage +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                            {
                                title: lang.SeaFloorAndLighthouses.toLocaleUpperCase(),
                                key: 'b1-5',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.seaFloorAndLighthouses + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.seaFloorAndLighthouses +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                            {
                                title: lang.CulHistHeritage.toLocaleUpperCase(),
                                key: 'b1-3',
                                link: POconstants.siteLinks.culHisHeritage + (currentLang === 'hr' ? '' : '?lang=' + currentLang),
                                onClick: () => hamburgerOn_set(false),
                            },
                        ],
                    },
                    {
                        title: lang.PlanVisit.toLocaleUpperCase(),
                        key: 'b2',
                        isInNavBar: true,
                        subMenus: [
                            {
                                title: lang.titles.serviceInfo.toLocaleUpperCase(),
                                key: 'b2-1',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.serviceInfo + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.serviceInfo +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                            {
                                title: lang.WhatToVisit.toLocaleUpperCase(),
                                key: 'b2-2',
                                subMenus: [
                                    {
                                        title: lang.titles.botanicGarden.toLocaleUpperCase(),
                                        key: 'b2-2-8',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.botanicGarden + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.botanicGarden +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                    {
                                        title: lang.titles.hospitality.toLocaleUpperCase(),
                                        key: 'b2-2-6',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.hospitality + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.hospitality +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                    {
                                        title: lang.Sailing.toLocaleUpperCase(),
                                        key: 'b2-2-5',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.sailing + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.sailing +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                    {
                                        title: lang.footpath.toLocaleUpperCase(),
                                        key: 'b2-2-3',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.footpath + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.footpath +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                    {
                                        title: lang.Beaches.toLocaleUpperCase(),
                                        key: 'b2-2-4',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.beaches + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.beaches +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                    {
                                        title: lang.titles.romanVilla.toLocaleUpperCase(),
                                        key: 'b2-2-2',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.romanVilla + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.romanVilla +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                    {
                                        title: lang.Diving.toLocaleUpperCase(),
                                        key: 'b2-2-1',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.diving + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.diving +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                    {
                                        title: lang.titles.vineyards.toLocaleUpperCase(),
                                        key: 'b2-2-7',
                                        link: !isOnImprecoSite
                                            ? POconstants.siteLinks.vineyards + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        externalLink: isOnImprecoSite
                                            ? process.env.REACT_APP_BASE_URL +
                                              POconstants.siteLinks.vineyards +
                                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                            : undefined,
                                        onClick: () => hamburgerOn_set(false),
                                    },
                                ],
                            },
                            {
                                title: lang.titles.touristMap.toLocaleUpperCase(),
                                key: 'b2-3',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.touristMap + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.touristMap +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                        ],
                    },
                    {
                        title: lang.ExplorePakleniIslands.toLocaleUpperCase(),
                        key: 'b3',
                        isInNavBar: true,
                        subMenus: [
                            {
                                title: lang.Photo.toLocaleUpperCase(),
                                key: 'b3-1',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.photo + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.photo +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                            {
                                title: lang.Video.toLocaleUpperCase(),
                                key: 'b3-2',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.video + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.video +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                            {
                                title: lang.publications.toLocaleUpperCase(),
                                key: 'b3-3',
                                link: !isOnImprecoSite
                                    ? POconstants.siteLinks.publications + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                externalLink: isOnImprecoSite
                                    ? process.env.REACT_APP_BASE_URL +
                                      POconstants.siteLinks.publications +
                                      (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                                    : undefined,
                                onClick: () => hamburgerOn_set(false),
                            },
                        ],
                    },
                    {
                        title: lang.DidYouSeeThis.toLocaleUpperCase(),
                        key: 'b4',
                        isInNavBar: true,
                        link: !isOnImprecoSite
                            ? POconstants.siteLinks.didYouSeeThis + (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                            : undefined,
                        externalLink: isOnImprecoSite
                            ? process.env.REACT_APP_BASE_URL +
                              POconstants.siteLinks.didYouSeeThis +
                              (currentLang === 'hr' ? '' : '?lang=' + currentLang)
                            : undefined,
                        onClick: () => hamburgerOn_set(false),
                    },
                ]}
            />

            <img src={HamburgerIcon} className="hamburgerButton" alt={lang.alt.nav.POLogo} onClick={() => toggleHamburger()}></img>
        </div>
    )
}

export default NavBar
