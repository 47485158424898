import React, { useState, useEffect } from 'react'

const NewResponsiveImage: Common.NewResponsiveImage.ResponsiveImage = props => {
    const [imageLoaded, imageLoaded_set] = useState<boolean>(false)
    const srcsetData = props.responsiveData ? props.responsiveData.srcsetData : undefined
    useEffect(() => {
        imageLoaded_set(false)
    }, [props.src, srcsetData])
    const borderStyle = props.spinnerColor ? { borderColor: props.spinnerColor + ' transparent transparent transparent' } : undefined
    const loadingDiv = (
        <div className={'spinnerContainer' + (props.noBackground ? '' : ' SpCBackground')} style={props.style}>
            <div className="lds-ring">
                <div style={borderStyle}></div>
                <div style={borderStyle}></div>
                <div style={borderStyle}></div>
                <div style={borderStyle}></div>
            </div>
        </div>
    )
    //preload image
    const image = new Image()
    image.src = props.src
    let srcSet = undefined
    if (props.responsiveData) {
        srcSet = ''
        for (let [index, row] of (props.responsiveData.srcsetData as any).entries()) {
            srcSet += `${row.src} ${row.width}${index === props.responsiveData.srcsetData.length - 1 ? '' : ', '}`
        }
        image.srcset = srcSet
        image.sizes = props.responsiveData.sizes
    }
    if (props.id) image.id = props.id
    image.onload = () => imageLoaded_set(true)
    return imageLoaded ? (
        <img
            src={props.src}
            style={props.style}
            {...props.imageProps}
            srcSet={srcSet}
            sizes={props.responsiveData ? props.responsiveData.sizes : undefined}
            alt={props.alt}
        ></img>
    ) : (
        loadingDiv
    )
}

export default NewResponsiveImage
