import React from "react";
import useInvertedColorsButton from "./useInvertedColorsButton";
import { Link } from "react-router-dom";
import { useLocale } from "../../../extensions/Locale/useLocale";

const InvertedColorsButton: Common.InvertedColorsButton.InvertedColorsButton = (props) => {
     const { currentLang } = useLocale<Locale.Keys>();
     const service = useInvertedColorsButton(props);
     const elementProps = {
          className: "invertedColorsButton",
          onClick: props.onClick,
          id: props.id,
          onMouseEnter: service.mouseEnterHandler,
          onMouseLeave: service.mouseLeaveHandler,
          style: {
               color: service.colors.text,
               background: service.gradient,
               backgroundColor: service.colors.background,
               boxShadow: service.boxShadow,
               ...props.style,
          },
     };
     if (props.link) {
          if (props.link.type === "internal") {
               return (
                    <Link {...elementProps} to={props.link.url + (currentLang === "hr" ? "" : "?lang=" + currentLang)}>
                         {props.text}
                    </Link>
               );
          } else if (props.link.type === "file") {
               return (
                    <a {...elementProps} href={props.link.url} target='_blank'>
                         {props.text}
                    </a>
               );
          } else if (props.link.type === "download") {
               return (
                    <a {...elementProps} href={props.link.url} download>
                         {props.text}
                    </a>
               );
          } else {
               return (
                    <a {...elementProps} href={props.link.url} target='_blank' rel='noopener noreferrer'>
                         {props.text}
                    </a>
               );
          }
     } else return <div {...elementProps}>{props.text}</div>;
};

export default InvertedColorsButton;
