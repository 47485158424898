import React from 'react'
import POconstants from '../../../Utils/POconstants.js'

const PageIsLoading: Common.PageIsLoading.PageIsLoading = props => {
    return (
        <div className="pageIsLoading" style={props.style}>
            <div className="lds-ring">
                <div style={{ borderColor: POconstants.colors.primary + ' transparent transparent transparent' }}></div>
                <div style={{ borderColor: POconstants.colors.primary + ' transparent transparent transparent' }}></div>
                <div style={{ borderColor: POconstants.colors.primary + ' transparent transparent transparent' }}></div>
                <div style={{ borderColor: POconstants.colors.primary + ' transparent transparent transparent' }}></div>
            </div>
        </div>
    )
}

export default PageIsLoading
